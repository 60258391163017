import React, { Component } from 'react';
import { graphql } from 'gatsby';
import RichTextConverter from '../components/atoms/RichTextConverter';
import SEO from '../components/molecules/SEO';
import styled from 'styled-components';
import PreHead from '../components/molecules/PreHead';
import Container from '../components/atoms/Container';
import EventEnquiryForm from '../components/molecules/EventEnquiryForm';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  margin: 6rem 0;
  @media(${props => props.theme.breakpoints.md}) {
    grid-template-columns: 4fr 1fr 3fr;
    grid-gap: 2rem;
  }
`;

const Title = styled.div`
  @media(${props => props.theme.breakpoints.md}) {
    grid-column: span 3;
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

const Content = styled.section`
  width: 100%;
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

const Sidebar = styled.div`
  position: relative;
  @media(${props => props.theme.breakpoints.md}) {
    grid-column: 3/4;
  }
`;

const SidebarInner = styled.div`
    position: sticky;
    top: 10rem;
    left: 0;
`;
const Details = styled.div`
    p { margin: 0.5rem 0; }
`;

class Event extends Component {
  render() {

    const { contentfulEvent } = this.props.data;
    const { title, content, duration, date, location, description } = contentfulEvent;

    return (
      <>
        <SEO title={title} description={description}/>
        <PreHead image="hero_00013.png"/>
        <Container>
          <Holder>
            <Title>
              <h1>{title}</h1>
            </Title>
            <Content>
              {content && <section><RichTextConverter content={content.json}/></section>}
            </Content>
            <Sidebar>
              <SidebarInner>
                <Details>
                  <h3>Details</h3>
                  {duration && <p>Duration - <b>{duration}</b></p>}
                  {date && <p>Start date - <b>{date}</b></p>}
                  {location && <p>Location - <b>{location}</b></p>}
                </Details>
                <EventEnquiryForm event={title}/>
              </SidebarInner>
            </Sidebar>
          </Holder>
        </Container>
      </>
    )
  }
}

export default Event;

export const pageQuery = graphql`
    query($id: String!) {
        contentfulEvent(id: { eq: $id }) {
            title
            content {
                json
            }
            location
            description
            date(formatString: "DD.MM.YY")
            duration
        }
    }
`;
